import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe';

import 'sections/ScreverOcxProgram/common.scss';

const VoteGamification = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver BTQ Event | Vote | Gamification"
      >
        <meta
          name="description"
          content="Vote for the topic for Gamification room"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program scr-btq-event">
        <HeaderCommon
          title="Vote for the topic for Gamification room"
          url="/events/screver-btq-program/"
        />

        <main>
          <div className="ocx-prog-wrap">
            <SurveyIframe
              src={'https://go.screver.com/capptoo/vote-gamification'}
              style={{ height: '850px' }}
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default VoteGamification;
